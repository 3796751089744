var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-lg-10",attrs:{"fluid":""}},[_c('SessionCreateBox'),_c('BaseTable',{ref:"table",attrs:{"title":"Sessioni da Gestire","headers":_vm.headers,"actions":_vm.actions,"module":"sessions"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":_vm.onRefresh}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Aggiorna Richieste ")],1)]},proxy:true},{key:"item.project_key",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"my-6 font-weight-bold",attrs:{"color":"primary","dark":"","label":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.project_key)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.project.name))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-6",attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.statusesMap[item.status])+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.typesMap[item.type])+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.created_by,'Cliente'))+" ")]}},{key:"item.customer_address",fn:function(ref){
var item = ref.item;
return [(item.type === 'video')?_c('span',[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.customer_address,'-'))+" ")]):_vm._e(),(item.type === 'remote')?_c('span',[_vm._v(" "+_vm._s(item.project_key)+"-"+_vm._s(item.session_code)+"-"+_vm._s(item.client_code)+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }