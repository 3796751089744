<template>
  <v-card>
    <v-card-text>
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form autocomplete="off" @submit.prevent="handleSubmit(onSubmit)">
          <v-row dense>
            <FormItem
              v-if="projects.length > 1"
              v-model="project_key"
              :values="projects"
              type="select"
              name="Iniziativa"
              rules="required"
              @input="onProjectSelected"
            />

            <FormItem
              v-if="settings.external_id_on_creation"
              v-model="external_id"
              type="text"
              name="ID Sessione"
              placeholder="Identificativo esterno di sessione"
              rules="required"
              :cols="2"
              :md="2"
              :lg="2"
              :xl="2"
            ></FormItem>
            <v-col :cols="12" :sm="12" :md="3" :lg="3" :xl="3">
              <v-select
                v-model="type"
                :items="selectOptions"
                label="Tipo"
                no-data-text="L'iniziativa non supporta sessioni"
                filled
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <FormItem
              v-model="customer_address"
              :disabled="!type"
              type="text"
              name="Recapito"
              label="Email o Cellulare"
              placeholder="Inserisci la mail o numero di cellulare"
              rules="required|customer_address"
              :cols="4"
              :md="5"
              :lg="3"
              :xl="3"
            ></FormItem>
            <v-col :cols="4" :md="3" :lg="3" :xl="2">
              <v-btn
                type="submit"
                :loading="loading"
                :disabled="invalid || !selectOptions.length"
                x-large
                color="primary"
                >{{ type === 'remote' ? 'Invia link' : 'Crea sessione' }}</v-btn
              >
            </v-col>
          </v-row>

          <v-alert v-if="alertVisible" color="blue" dense type="info" prominent>
            <strong>NB:</strong> in caso di problemi di navigazione, assicurarsi
            che il cliente sia connesso usando la rete dati e non il WiFi della
            Station e che sia dotato di auricolari nel caso in cui venga usato
            lo stesso smartphone da cui sta chiamando.
          </v-alert>
        </form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import FormItem from '@components/forms/FormItem.vue'

const { mapFields: mapSession } = createHelpers({
  getterType: 'sessions/getCurrent',
  mutationType: 'sessions/SET_CURRENT_FIELDS',
})

export default {
  name: 'SessionCreateBox',
  components: { FormItem },
  computed: {
    ...mapState('adminProjects', {
      projects: (state) => state.list,
    }),
    ...mapState('sessions', ['loading']),
    ...mapGetters('adminProjects', {
      capabilities: 'getCapabilities',
      settings: 'getSettings',
    }),
    ...mapSession(['type', 'customer_address', 'project_key', 'external_id']),

    /** Unclear what this is supposed to indicate */
    alertVisible() {
      return (
        this.type === 'video' &&
        this.customer_address &&
        this.customer_address.length &&
        ['VFA', 'VFB', 'VFP'].includes(this.project_key)
      )
    },
    selectOptions() {
      /* eslint-disable camelcase */
      const { video, remote_mobile, remote_desktop } = this.capabilities
      const options = []
      if (video)
        options.push({
          value: 'video',
          text: 'Avvia video assistenza',
        })
      if (remote_mobile || remote_desktop)
        options.push({
          value: 'remote',
          text: 'Richiedi accesso remoto su dispositivo cliente',
        })
      return options
    },
  },
  mounted() {
    if (this.projects.length) {
      const value = this.projects[0].value
      this.project_key = value
      this.onProjectSelected(value)
    }
  },
  methods: {
    ...mapMutations('sessions', {
      set: 'SET_CURRENT',
      reset: 'RESET_CURRENT',
    }),
    ...mapMutations('adminProjects', {
      setActiveProject: 'SET_ACTIVE',
    }),
    ...mapActions('sessions', ['create']),
    ...mapActions('adminProjects', ['setCurrent']),

    async onSubmit() {
      const session = await this.create()

      if (session.type === 'video') {
        this.$router.push({
          name: 'sessions_manage',
          params: { id: session.id },
        })
      }
    },

    onProjectSelected(value) {
      this.setActiveProject(value)
      if (this.selectOptions.find((el) => el.value === 'video'))
        this.type = 'video'
      else if (this.selectOptions.find((el) => el.value === 'remote'))
        this.type = 'remote'
      else this.type = null
    },
  },
}
</script>

<style scoped></style>
