<template>
  <v-container fluid class="px-lg-10">
    <SessionCreateBox />

    <BaseTable
      ref="table"
      title="Sessioni da Gestire"
      :headers="headers"
      :actions="actions"
      module="sessions"
    >
      <template v-slot:actions>
        <v-btn small outlined @click="onRefresh">
          <v-icon left>mdi-refresh</v-icon>
          Aggiorna Richieste
        </v-btn>
      </template>

      <template v-slot:item.project_key="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="primary"
              dark
              class="my-6 font-weight-bold"
              label
              v-bind="attrs"
              v-on="on"
            >
              {{ item.project_key }}
            </v-chip>
          </template>
          <span>{{ item.project.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark class="my-6">
          {{ statusesMap[item.status] }}
        </v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        {{ typesMap[item.type] }}
      </template>

      <template v-slot:item.created_by="{ item }">
        {{ item.created_by | placeholder('Cliente') }}
      </template>

      <template v-slot:item.customer_address="{ item }">
        <span v-if="item.type === 'video'">
          {{ item.customer_address | placeholder('-') }}
        </span>
        <span v-if="item.type === 'remote'">
          {{ item.project_key }}-{{ item.session_code }}-{{ item.client_code }}
        </span>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import BaseTable from '@components/structure/base-table.vue'
import SessionCreateBox from '@components/sessions/SessionCreateBox.vue'

export default {
  name: 'PendingSessions',
  components: { SessionCreateBox, BaseTable },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: 'Progetto',
        value: 'project_key',
      },
      {
        text: 'Stato',
        value: 'status',
      },
      {
        text: 'Tipo',
        value: 'type',
      },
      {
        text: 'Identificativo',
        value: 'customer_address',
      },
      {
        text: 'Creata da',
        value: 'created_by',
      },
      {
        text: 'Scadenza Sessione',
        value: 'expires_at',
      },
      {
        text: 'Inserito',
        value: 'created_at',
      },
    ],
    actions: [
      {
        label: 'CONNETTI',
        to: ({ id }) => ({ name: 'sessions_manage', params: { id } }),
        color: 'primary',
      },
    ],
  }),
  mounted() {
    this.setScopes(['project', 'pending'])
  },
  destroyed() {
    this.setScopes([])
  },
  computed: {
    ...mapGetters('sessions', ['statusesMap', 'typesMap']),
  },
  methods: {
    ...mapMutations('sessions', {
      setScopes: 'SET_SCOPES',
    }),
    onRefresh() {
      this.$refs.table.refresh()
    },
    getColor(status) {
      switch (status) {
        case 'waiting':
          return 'gray'
        case 'offline':
          return 'red'
        default:
          return 'green'
      }
    },
  },
}
</script>
